import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Paper, Tab, Tabs } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import MonthView from './components/MonthView/MonthView';

import server from 'api/server';

import MainWeeklyWorkoutPlan from './components/MainWeeklyWorkoutPlan';
import PreferencesContext from 'context/Preferences/PreferencesContext';

const useStyles = makeStyles(theme => ({
  root: {
    // margin: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      // padding: theme.spacing(2)
      margin: theme.spacing(2)
    }
  }
}));

export function getDayOfWeekAndDate(date) {
  if (!date) return { dayOfWeek: '', formattedDate: '' };
  const dateObject = new Date(date);

  const daysOfWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ];
  const dayIndex = dateObject.getDay();
  const dayOfWeek = daysOfWeek[dayIndex];

  const day = String(dateObject.getDate()).padStart(2, '0');
  const month = String(dateObject.getMonth() + 1).padStart(2, '0');
  const formattedDate = `${day}/${month}`;

  return {
    dayOfWeek,
    formattedDate
  };
}

function getCompleteWeekWorkouts(runnerId, trainerUserId, workouts, date) {
  // const runnerId = workouts[0]?.runnerId;
  // const trainerUserId = workouts[0]?.trainerUserId;

  // Create an array to hold the completed week's workouts
  const completedWeek = [];

  // Create a date object for the current date
  // const currentDate = new Date();

  // Find the start date (Sunday) of the current week
  const startDate = new Date(date);
  // Iterate over the days of the week (Sunday to Saturday)
  for (let i = 0; i < 7; i++) {
    // Create a date object for the current day
    const currentDate = new Date(startDate);
    currentDate.setDate(currentDate.getDate() + i);

    // Find the workout for the current day
    const workout = workouts.find(w => {
      const workoutDate = new Date(w.dateObject);
      return (
        workoutDate.getFullYear() === currentDate.getFullYear() &&
        workoutDate.getMonth() === currentDate.getMonth() &&
        workoutDate.getDate() === currentDate.getDate()
      );
    });

    // Check if a workout exists for the current day
    if (workout) {
      const { dayOfWeek, formattedDate } = getDayOfWeekAndDate(
        workout.dateObject
      );
      // Add the workout to the completed week array
      completedWeek.push({
        ...workout,
        isWorkout: true,
        dayOfWeek,
        date: formattedDate
      });
    } else {
      const { dayOfWeek, formattedDate } = getDayOfWeekAndDate(currentDate);
      // Add a placeholder workout object for the current day
      const placeholderWorkout = {
        runnerId,
        trainerUserId,
        dateObject: currentDate.toISOString(),
        isWorkout: false,
        dayOfWeek,
        date: formattedDate
      };
      completedWeek.push(placeholderWorkout);
    }
  }

  return completedWeek;
}

const isItCurrentlyAfterSaturdayAt1600 = date => {
  var currentDate = new Date(date);
  var nextWeekBegin = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() + (7 - currentDate.getDay())
  );
  return nextWeekBegin - currentDate < 8 * 60 * 60 * 1000;
};

const WorkoutPlan = () => {
  const classes = useStyles();
  const preferences = React.useContext(PreferencesContext);
  const startDay = preferences.get('startDay');

  // const getFirstDayOfWeek = () => {
  //   const today = new Date();
  //   let firstDayOfWeek = new Date(
  //     today.getFullYear(),
  //     today.getMonth(),
  //     today.getDate() - today.getDay() + startDay
  //   );

  //   if (firstDayOfWeek.getTime() > today.getTime()) {
  //     firstDayOfWeek.setDate(firstDayOfWeek.getDate() - 7);
  //   }

  //   return firstDayOfWeek;
  // };

  const getFirstDayOfWeek = () => {
    // startDay: 0 = Sunday, 1 = Monday, etc.
    const today = new Date();
    const currentDay = today.getDay(); // Day of the week (0-6)

    // Calculate the first day of the current week based on startDay
    let firstDayOfWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - currentDay + startDay
    );

    // Adjust for days before the current startDay
    if (currentDay < startDay) {
      firstDayOfWeek.setDate(firstDayOfWeek.getDate() - 7);
    }

    // Determine if today is the last day of the week after 16:00
    const lastDayOfWeek = (startDay + 6) % 7; // Last day of the week
    if (
      currentDay === lastDayOfWeek && // Today is the last day of the week
      today.getHours() >= 16 // After 16:00
    ) {
      // Set first day to the next day (start of the next week)
      firstDayOfWeek.setDate(firstDayOfWeek.getDate() + 7);
    }

    return firstDayOfWeek;
  };

  const [startDate, setStartDate] = useState(getFirstDayOfWeek());
  const [runnersWeeklyWorkoutsPlan, setRunnersWeeklyWorkoutsPlan] = useState(
    []
  );

  const updateWeekView = () => {
    const getRunnersWeeklyWorkoutsPlan = async () => {
      const runnersWeeklyWorkoutsPlanFromDB = await server.post(
        'workout/runnersWeeklyWorkoutPlan',
        { startDate: new Date(startDate.setHours(12, 0, 0, 0)) }
      );

      return runnersWeeklyWorkoutsPlanFromDB.value;
    };

    setRunnersWeeklyWorkoutsPlan([]); // Reset state before API call
    getRunnersWeeklyWorkoutsPlan()
      .then(runnersWeeklyWorkoutsPlan => {
        return runnersWeeklyWorkoutsPlan.map(runner => {
          const completeWorkoutsWeek = getCompleteWeekWorkouts(
            runner.runnerId,
            runner.trainerUserId,
            runner.workouts,
            startDate
          );
          return { ...runner, workouts: completeWorkoutsWeek };
        });
      })
      .then(res => {
        setRunnersWeeklyWorkoutsPlan(res);
      });
  };

  useEffect(updateWeekView, [startDate]);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Paper square>
        <Tabs
          indicatorColor="primary"
          onChange={handleChange}
          textColor="primary"
          value={value}>
          <Tab label="Weekly View" />
          <Tab label="Monthly View" />
        </Tabs>
      </Paper>
      <div style={{ display: value === 0 ? 'block' : 'none' }}>
        <Paper className={classes.root} square>
          <CssBaseline />
          <MainWeeklyWorkoutPlan
            weeklyWorkoutPlan={runnersWeeklyWorkoutsPlan}
            setDate={setStartDate}
            updateWeekView={updateWeekView}
          />
        </Paper>
      </div>
      <div style={{ display: value === 1 ? 'block' : 'none' }}>
        <Grid container justifyContent="center">
          <MonthView updateWeekView={updateWeekView} />
        </Grid>
      </div>
    </>
  );
};

export default WorkoutPlan;
